import React, {Component} from "react"
import Layout from "../components/layout"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField/TextField"
import SEO from "../components/seo"
import WaveSvg from "../assets/images/Wave.svg"
import {isValidContentfulImage, isValidPhone, isValidSectionField} from "../common/utils/CommonUtils"
import {graphql} from "gatsby"
import ArrowNavigationIcon from "@material-ui/icons/NearMe"
import {SegmentAnalytics} from "../common/utils/SegmentAnalytics";
import {SegmentEvents} from "../common/utils/SegmentSpec";
import BreadCrumbs from "../components/bread-crumbs";
import {BranchUtils} from "../common/utils/BranchUtils";
import "../pages/join-group.scss";


const breadCrumbsList  = [

    {
        title: "Home",
        link: "/",
    },
    {
        title: "Get Started",
        link: "/get-started",
    },
    {
        title: "Support Groups",
        link: "/support-groups",
    },
    {
        title: "All Groups",
        link: "/support-groups/online-support-groups",
    }

]
export default class JoinGroup extends Component {
    constructor(props) {
        super(props);
        this.pageData = props.data.contentfulGroupDetailPage;
        this.state = {
            phone: "",
            success: false,
            error: false,
        }

    }

    sendPhone(event, phoneNumber, groupName) {
        event.preventDefault();
        const phone = isValidPhone(phoneNumber);
        if (phone) {

            let currentPage = typeof window !== 'undefined'
                ? window.location.pathname : '';

            currentPage = currentPage.split('/')[2];
            if (currentPage === "") {
                currentPage = "home-page"
            }
            SegmentAnalytics.track(
                SegmentEvents.USER_REQUEST_FOR_JOIN_GROUP_GET_THE_APP, {
                    phone,
                    'formLocation': currentPage,
                    appRequested: true,
                });

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'formLocation': currentPage,
                'event': 'GET_THE_APP',
                'phone': phone
            });

            BranchUtils.desktopSendSMS(phone, currentPage, 'Group Joined', groupName);

            this.setState({
                success: true,
                error: false
            })
        } else {
            console.log("this.state.error", this.state.error);
            this.setState({
                error: true,
                success: false,
                phone: "",
            })
        }
    };

    render() {
        const {topSection, benefits, groupOverview, groupBullets, groupOrganizer} = this.pageData;
        return (
            <Layout>
                <SEO title="Group Download App"/>
                <BreadCrumbs currentPage={topSection.title}  breadCrumbsList={breadCrumbsList}/>
                <div className="new-common-top-header">
                    <div className="new-common-top-inner join-group">
                        <div className="new-common-top-pattern-content">
                            <h2 className="new-common-top-main-head">You’ve
                                joined
                                the group!</h2>
                            <p className="new-common-top-sub-head">
                                Download the app to begin
                            </p>
                            <form method="post" onSubmit={(event) => {
                                this.sendPhone(event, this.state.phone, groupOverview.title)
                            }}>
                                <div className="get-app-input-wrap">
                                    <div className="input-box">
                                        <TextField
                                            id="phone-input"
                                            className="number-field"
                                            placeholder="Enter your phone number"
                                            value={this.state.phone}
                                            margin="normal"
                                            onChange={event => {
                                                const {value} = event.target;
                                                this.setState({
                                                    phone: value,
                                                    error: false,
                                                    success: false,
                                                })
                                            }}
                                        />
                                    </div>
                                    <Button
                                        className="blue-arrow-btn"
                                        color="primary"
                                        type="submit"
                                    >
                                        Get the App <ArrowNavigationIcon/>
                                    </Button>
                                    {this.state.error ? <p
                                        className="error-msg">Please Enter Valid
                                        Phone</p> : null}
                                    {this.state.success ? <p
                                        className="success-msg">Got it! Sending you a text with the link</p> : null}
                                </div>
                            </form>
                        </div>
                        <div className="join-group-wrapper">
                            <div className="join-group-info">
                                <img className="join-group-img"
                                     src={topSection?.imageOrVideo?.file?.url}
                                     alt="Icon"/>
                                <p className="reframing-title">{topSection.title}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="group-info-wrapper">
                    <div className="group-info-inner">
                        <div className="group-info-row">
                            {
                                topSection.groupMeta
                                && topSection.groupMeta.map(groupMeta => (
                                    <div className="single-group-info">
                                        {
                                            isValidContentfulImage(
                                                groupMeta.image) && (
                                                <img className="info-icon"
                                                     src={groupMeta.image.file.url}
                                                     alt="Group Icon"/>
                                            )
                                        }
                                        <div className="info-content">
                                            {
                                                isValidSectionField(groupMeta,
                                                    "name") &&
                                                <p className="title">{groupMeta.name}</p>
                                            }
                                            {
                                                isValidSectionField(groupMeta,
                                                    "description") &&
                                                <p className="des">{groupMeta.description}</p>
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        {
                            isValidSectionField(topSection,
                                "groupExtraDescription") &&
                            <p className="join-group-para">
                                {topSection.groupExtraDescription.groupExtraDescription}
                            </p>
                        }
                    </div>
                </div>


                <div className="common-detailed-wave-wrapper"
                     style={{backgroundImage: `url(${WaveSvg})`}}>
                    <div className="wave-bg">
                        <div className="common-detailed-feature-inner">
                            {
                                benefits && benefits.map(benefit => (
                                    <div
                                        className="single-common-detailed-feature">
                                        {
                                            isValidContentfulImage(
                                                benefit.image) && (
                                                <img
                                                    className="single-common-detailed-img"
                                                    src={benefit.image.file.url}
                                                    alt="Icon"/>
                                            )
                                        }
                                        <div
                                            className="common-detailed-feature-content">
                                            {
                                                isValidSectionField(benefit,
                                                    "name") &&
                                                <h2 className="head">{benefit.name}</h2>
                                            }
                                            {
                                                isValidSectionField(benefit,
                                                    "description") &&
                                                <p className="para">{benefit.description}</p>
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>


                <div className="course-descr-wrapper">
                    <div className="course-descr-inner">
                        {groupOverview && (
                            <div className="course-descr-left-side">
                                {
                                    isValidSectionField(groupOverview, "title")
                                    &&
                                    <h2 className="course-descr-head">{groupOverview.title}</h2>
                                }
                                {
                                    isValidSectionField(groupOverview,
                                        "description") &&
                                    <p className="course-descr-para"
                                       dangerouslySetInnerHTML={{__html: groupOverview.description.description}}></p>
                                }
                            </div>
                        )}
                        {groupBullets && (
                            <div className="course-descr-right-side">
                                {groupBullets.bulletPoints &&
                                <h2 className="course-descr-head">{groupBullets.title}</h2>}
                                <ul>
                                    {
                                        groupBullets.bulletPoints
                                        && groupBullets.bulletPoints.map(
                                            bullet => (
                                                <li>
                                                    <img
                                                        src={require(
                                                            "../assets/images/checkmark-circle-outlined.svg")}
                                                        alt="icon"/>
                                                    <p>{bullet}</p>
                                                </li>
                                            ))
                                    }
                                </ul>
                            </div>

                        )}

                    </div>
                </div>


                {groupOrganizer && (
                    <div className="organizer-box-wrapper">
                        <div className="organizer-box-inner">
                            {
                                isValidContentfulImage(
                                    groupOrganizer.providerImage) && (
                                    <img className="group-organizer-img"
                                         src={groupOrganizer.providerImage.file.url}
                                         alt="Organizer"/>
                                )
                            }
                            <div className="organizer-info">
                                <div className="organizer-info-text">
                                    <h2 className="main-text">Group
                                        Organizer</h2>
                                    {
                                        isValidSectionField(groupOrganizer,
                                            "providerName") &&
                                        <h3 className="sub-text">{groupOrganizer.providerName}</h3>
                                    }
                                    {
                                        isValidSectionField(groupOrganizer,
                                            "approach") &&
                                        <p className="organizer-para">{groupOrganizer.approach.approach}</p>
                                    }
                                    <a href={`/online-therapy/providers/${groupOrganizer.slug}/`}>
                                        <Button className="outline-btn">View
                                            Profile</Button>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>

                )}

            </Layout>
        )
    }
}

export const query = graphql`
query groupBySlugJoin($slug:String) {
  contentfulGroupDetailPage(slug: {eq: $slug}) {
    id
    topSection {
      title
         ... on ContentfulPageTopSection {
      description {
        description
      }
           imageOrVideo {
        file {
          contentType
          url
        }
      }
      groupMeta {
        name
        ... on ContentfulSectionMeta{

        description
        image {
          file {
            contentType
            url
          }
        }
      }
     }


      groupExtraDescription {
        groupExtraDescription
      }
      }
    }
    benefits {
      name
      ... on ContentfulSectionMeta{
      description
      image {
        file {
          url
          contentType
        }
      }
      }
    }
    groupOverview {
      title
      description {
        description
      }
    }
    groupBullets {
      bulletPoints
      title
    }
    groupOrganizer {
      providerName
      providerImage {
        file {
          contentType
          url
        }
      }
      slug
      approach {
        approach
      }
    }
  }
}`;
